import apiRequest from '../../services/api-request'
import { stripHostFromAttachment } from '../../services/attachment-data-cleaner'
import {
  SET_CURRENT_MERGED_JOB,
  SET_MERGED_JOB_LOADING_STATE,
  SET_MERGED_JOB_ERROR_STATE
} from '../keys-mutations'
import { CREATE_MERGE_JOB, LOAD_MERGED_FILES } from '../keys-actions'
import {
  CURRENT_MERGED_JOB,
  MERGED_JOB_LOADING_STATUS,
  MERGED_JOB_ERROR_STATUS
} from '../keys-getters'
import Vue from 'vue'

export default {
  state: {
    isLoading: false,
    isError: false,
    currentMergedJob: {}
  },
  mutations: {
    [SET_CURRENT_MERGED_JOB]: (state, mergedJob) => {
      state.currentMergedJob = mergedJob
    },
    [SET_MERGED_JOB_LOADING_STATE]: (state, isLoading) => {
      state.isLoading = isLoading
    },
    [SET_MERGED_JOB_ERROR_STATE]: (state, isError) => {
      state.isError = isError
    }
  },
  getters: {
    [CURRENT_MERGED_JOB]: state => {
      return state.currentMergedJob || {}
    },
    [MERGED_JOB_LOADING_STATUS]: state => {
      return state.isLoading
    },
    [MERGED_JOB_ERROR_STATUS]: state => {
      return state.isError
    }
  },
  actions: {
    [CREATE_MERGE_JOB]: async (
      { commit, dispatch, getters },
      { vacancyId, prospectIds }
    ) => {
      commit(SET_MERGED_JOB_LOADING_STATE, true)
      const { companyName } = getters.CURRENT_TENANT
      const currentDate = new Date()
      const currentDateFormatted =
        '' +
        currentDate.getUTCFullYear() +
        (currentDate.getUTCMonth() + 1) +
        currentDate.getUTCDate() +
        currentDate.getUTCHours() +
        currentDate.getUTCMinutes() +
        currentDate.getUTCSeconds()
      try {
        const mergeJobId = await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/mergeJobs/vacancy/${vacancyId}`,
          data: {
            prospectIds,
            title: `${companyName}_bulk_${currentDateFormatted}.pdf`
          }
        })
        await dispatch(LOAD_MERGED_FILES, { mergeJobId, vacancyId })
      } catch (error) {
        Vue.notify({
          type: 'warning',
          text: `Deze actie is niet gelukt. Neem contact op met de supportdesk. ${error.message}`
        })
        commit(SET_MERGED_JOB_ERROR_STATE, true)
        throw error
      }
    },
    [LOAD_MERGED_FILES]: async (
      { commit, dispatch },
      { mergeJobId, vacancyId, attempts = 0, wait = 500 }
    ) => {
      const MAX_ATTEMPTS = 6
      const WAIT_FACTOR = 3

      try {
        const mergeJobs = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/mergeJobs?_=1563546425473`
        })
        const mergeJob = mergeJobs
          .filter(job => job.id === mergeJobId)
          .pop()
        if (mergeJob && mergeJob.url !== null) {
          stripHostFromAttachment(mergeJob, 'url')
          commit(SET_CURRENT_MERGED_JOB, mergeJob)
          commit(SET_MERGED_JOB_LOADING_STATE, false)
          return
        }

        wait *= WAIT_FACTOR
        attempts++
        if (attempts >= MAX_ATTEMPTS) {
          commit(SET_MERGED_JOB_ERROR_STATE, true)
          commit(SET_MERGED_JOB_LOADING_STATE, false)
          throw new Error(
            'Er is een fout opgetreden bij het laden van het bestand'
          )
        } else {
          setTimeout(() => {
            dispatch(LOAD_MERGED_FILES, { mergeJobId, vacancyId, attempts, wait })
          }, wait)
        }
      } catch (error) {
        Vue.notify({
          type: 'warning',
          text: `Deze actie is niet gelukt. Neem contact op met de supportdesk. ${error.message}`
        })
        throw error
      }
    }
  }
}
