<template>
  <div
    class="top-bar text-left bg-new small py-3 alert alert-warning m-0 border-0"
  >
    <!-- Wij zijn ONE aan het updaten om de gebruikerservaring te verbeteren. Mocht u
    een probleem vinden neem dan contact met ons op via
    <b-link class="alert-link" href="mailto:supportdesk@jobsrepublic.nl"
      >supportdesk@jobsrepublic.nl</b-link
    >. -->
    <b>Onderhoudsmelding ONE</b> <br/>

    Geachte relatie Hellopublic, ONE gebruiker,<br/>

    Op dit moment is ONE niet beschikbaar vanwege noodzakelijke onderhoudswerkzaamheden. Deze werkzaamheden zijn
    nodig om de kwaliteit en betrouwbaarheid van onze dienstverlening te waarborgen. <br/><br/>

    Hersteldatum en -tijd: <br/>
    ONE zal op <b>maandag 22 april om 10:00 uur</b> weer volledig operationeel zijn. <br/><br/>

    Wij begrijpen dat dit ongemak kan veroorzaken en waarderen uw geduld en begrip terwijl we werken aan het verbeteren van onze service.

    Voor verdere vragen kunt u contact opnemen met onze support desk
    <b-link class="alert-link" href="mailto:supportdesk@jobsrepublic.nl"
      >supportdesk@jobsrepublic.nl</b-link>. <br/>

    Hartelijk dank voor uw vertrouwen en begrip.
  </div>
</template>

<script>
export default {
  name: 'infoBar'
}
</script>
